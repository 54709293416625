.my-devices-active-container {
    width: 100%;
    margin-bottom: 40px;
}
.my-devices-add-new {
    color: white;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    background-color: #a94442;
    padding: 5px 10px;
    border: none;
    outline: none;
}
.my-devices-add-new-button-top {
    float: right;
}
.my-devices-add-new-main {
    margin-top: 10px;
}
.my-devices-add-new:hover {
    color: #aaaaaa;
}
.my-devices-add-new-icon {
    font-size: 14px;
    display: inline-block;
    margin-right: 5px;
    padding-top: 4px;
}
.my-devices-add-new-text {
    font-size: 13px;
    display: inline-block;
}
.my-devices-active-header {
    width: 100%;
    margin: 0;
    background-color: #a94442;
    color: white;
    font-size: 1.2em;
    font-weight: normal;
    padding: 10px 20px;
    position: relative;
}
.my-devices-active-details-container {
    display: inline-block;
    width: 50%;
    border: 1px solid #eeeeee;
    padding: 15px 20px;
}
.my-devices-active-details-full-container {
    border: 1px solid #eeeeee;
    padding: 15px 20px;
    width: 100%;
}
.my-devices-active-details-header {
    font-size: 0.8em;
    margin-bottom: 0.4em;
}
.my-devices-active-details-info {
    font-size: 1em;
}

.my-devices-active-header-icon-container {
    position: absolute;
    right: 10px;
    top: 11px;
}
.my-devices-active-header-icon {
    color: white;
    border: 0;
    outline: 0;
    padding: 0;
    background: none;
    font-size: inherit;
    margin: 0 0 0 10px;
    cursor: pointer;
}

.my-devices-warranty {
    margin-bottom: 10px;
}
.my-devices-warranty:last-of-type {
    margin-bottom: 25px;
}
.my-devices-warranty-status-text {

}
.my-devices-warranty-icon {
    vertical-align: top;
    display: inline-block;
    margin-right: 5px;
}
.my-devices-warranty-date {
    font-size: 0.9em;
    color: #666666;
}

.my-device-info.MuiPaper-root {
    margin-bottom: 15px;
    background: #CFD8DC;
}
.my-device-info .my-device-info-container {
    padding: 12px;
    font-size: 0.9em;
}
.my-device-info .my-device-info-container:last-child {
    padding-bottom: 12px;
}
.my-device-info .my-device-info-container span {
    display: inline-block;
    width: calc(100% - 35px);
    vertical-align: middle;
}
.my-device-info-icon {
    font-size: 1.2em;
    margin-right: 15px;
    vertical-align: top;
    color: #212121;
    display: inline-block;
    width: 20px;
}

@media only screen and (max-width: 700px) {

}
