@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');

html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

/* Clearfix */
.clearfix::after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

/* Everything box-sizing */
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

/* Some reset CSS  */
textarea {
  font-family: inherit;
}
