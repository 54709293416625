.half-page-image-right-container {
    height: 100%;
}
.half-page-image-right-content-side {
    width: 600px;
    height: 100%;
    float: left;
    position: relative;
    overflow: auto;
    padding: 20px 60px;
}
.half-page-image-right-img-side {
    width: calc(100% - 600px);
    height: 100%;
    float: right;
}
.half-page-image-right-content-holder {
    background: white;
    padding: 20px 60px;
}
@media only screen and (max-width: 1050px) {
    .half-page-image-right-content-side {
        width: 100%;
        float: none;
        z-index: 3;
    }
    .half-page-image-right-img-side {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        float: none;
        z-index: 2;
    }
}
@media only screen and (max-width: 450px) {
    .half-page-image-right-content-holder {
        padding: 0;
    }
}
